import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'

import rem from '../utils/rem'
import { mobile } from '../utils/media'
import { navbarHeight, navbarMaxWidth, globalPadding } from '../utils/sizes'
import { main, white } from '../utils/colours.js'
import Layout from '../components/Layout'

const Hero = styled.div`
  color: ${white};
  width: 100vw;
  height: calc(100vh - ${rem(navbarHeight)});
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: ${main};
  & h1,
  & p {
    text-shadow: 0px 4px 2px rgba(0, 0, 0, 0.025);
  }
  & *::selection {
    background-color: white;
    color: ${main};
  }
`

const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: space-between;
  max-width: ${rem(navbarMaxWidth)};
  height: 100%;
  margin: 0 auto;
  ${mobile(css`
    flex-direction: column;
  `)}
`

const HeroContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: ${rem(globalPadding)};
  ${p => p.span && css`
    width: ${p.span}%;
  `}
  ${p => p.backgroundImage && css`
    background: url(${p.backgroundSrc}), url(${p.backgroundBase64});
    background-size: cover;
    background-position: center center;
  `}
  ${mobile(`
    width: 100%;
  `)}
`

class IndexComponent extends Component {
  render() {
    const {data} = this.props
    return (
      <Layout>
        <Hero>
          <HeroWrapper>
            <HeroContainer span="50">
              <h1>Professional Plumbing & Heating Services.</h1>
              <p>Commercial, Domestic, Gas & Renewable Energy</p>
            </HeroContainer>
            <HeroContainer
              span="50"
              backgroundImage="true"
              backgroundBase64={data.heroImage1.sizes.base64}
              backgroundSrc={data.heroImage1.sizes.src}
            />
          </HeroWrapper>
        </Hero>
      </Layout>
    )
  }
}

export const query = graphql`
  query heroImageQuery{
    heroImage1: imageSharp(fluid: {originalName: {regex: "/7D-127.jpg/"}}) {
      sizes(maxWidth: 1200) {
        base64
        src
      }
    }
  }
`

export default IndexComponent
